import React, {useState, useEffect} from "react";
import supabase from "./client";

export default function EditModal({ poleNumber, closeModal }) {
    const [notes, setNotes] = useState("");

    useEffect(() => {
        async function fetchData() {
            const { data: matching_pole, error } = await supabase
                .from('all_records')
                .select('*')
                .match({'PowerPoleNo': poleNumber})

            if (error) console.error(error);

            if (matching_pole) {
                setNotes(matching_pole[0]["Notes"] ?? "")
            }
        }

        fetchData();

    }, [poleNumber])

    const saveNotes = async () => {
        const { data: matching_pole, error } = await supabase
            .from('all_records')
            .update({"Notes": notes})
            .match({'PowerPoleNo': poleNumber})

        if (error) console.error(error);

        if (matching_pole) {
            setNotes(matching_pole["Notes"]);
        }

        closeModal();
    }

  return (
      <div className="absolute bottom-3 left-3 right-3 bg-gray-200 rounded shadow-xl ring-2 ring-gray-400 px-3 py-2 h-80 z-20">
          <h3 className="text-xl mb-3"><span className="font-bold">Pole Number:</span> {poleNumber}</h3>
          Notes:
          <textarea className="ring-2 ring-gray-500 w-full h-48 p-2" style={{ resize: "none"}} onChange={event => setNotes(event.target.value)} value={notes} />
          <div className="flex flex-row mt-1">
            <button onClick={() => closeModal()} className="flex-1 bg-transparent flex items-center justify-center py-2 px-3 text-sm text-gray-700">
                Cancel
            </button>
              <button onClick={() => saveNotes()} className="flex-1 rounded-lg bg-green-600 flex items-center justify-center py-2 px-3 hover:bg-green-800 text-gray-50 font-semibold">
                  Save
              </button>
          </div>
      </div>
  )
};