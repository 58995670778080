import React, {useRef, useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import supabase from "./client";
import useSupercluster from "use-supercluster";
import Marker from "./marker";
import "./App.css";
import EditModal from "./edit-modal";

const ClusterMarker = ({ children }) => children;

function App() {
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);
    const [clusterPoints, setClusterPoints] = useState([]);
    const [userLocation, setUserLocation] = useState({});
    const [selectedMarker, setSelectedMarker] = useState(null);

    async function fetchData() {
        let {data: all_records, error} = await supabase
            .from('all_records')
            .select('*')

        if (error) {
            console.error(error);
        }

        if (all_records && all_records.length > 0) {
            const points = all_records.map(record => ({
                type: "Feature",
                properties: {cluster: false, recordType: record["Type"], recordHasNotes: !!record["Notes"], recordID: record["PowerPoleNo"]},
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(record["Longitude"]),
                        parseFloat(record["Latitude"])
                    ]
                }
            }));

            setClusterPoints(points);
        }

    }

    const closeModal = () => {
        setSelectedMarker(null);
        fetchData();
    }

    useEffect(() => {
        fetchData();


        if ("geolocation" in navigator) {
            setInterval(() => {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude});
                    },
                    error => console.error(error)
                );
            }, 1000);
        }
    }, []);

    const {clusters, supercluster} = useSupercluster({
        points: clusterPoints,
        bounds,
        zoom,
        options: {radius: 250, maxZoom: 16}
    });

    return (
        <div style={{height: '100vh', width: '100%'}}>
            { selectedMarker ? <EditModal poleNumber={selectedMarker.properties.recordID} closeModal={() => closeModal()} /> : null }
            <GoogleMapReact
                bootstrapURLKeys={{key: "AIzaSyA8NjOzbap5R6Z1gD6FWkk35KYMgmqlQQ0"}}
                defaultCenter={{lat: 39.007857, lng: -105.050281}}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map}) => {
                    mapRef.current = map;
                }}
                onChange={({zoom, bounds}) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
                options={{zoomControl: false, fullscreenControl: false, mapTypeControl: true}}
            >
                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <ClusterMarker
                                key={`cluster-${cluster.id}`}
                                lat={latitude}
                                lng={longitude}
                            >
                            <div className="bg-gray-500 ring-2 ring-gray-700 rounded-full px-4 py-4 flex items-center text-gray-50 text-base font-semibold justify-center"
                                style={{
                                    width: `${10 + (pointCount / clusterPoints.length) * 20}px`,
                                    height: `${10 + (pointCount / clusterPoints.length) * 20}px`
                                }}
                                onClick={() => {
                                    const expansionZoom = Math.min(
                                        supercluster.getClusterExpansionZoom(cluster.id),
                                        20
                                    );
                                    mapRef.current.setZoom(expansionZoom);
                                    mapRef.current.panTo({lat: latitude, lng: longitude});
                                }}
                            >
                                {pointCount}
                            </div>
                            </ClusterMarker>
                        );
                    }

                    return (
                        <ClusterMarker
                            key={`pole-${cluster.properties.recordID}`}
                            lat={latitude}
                            lng={longitude}>
                            <Marker type={cluster.properties.recordType} notes={cluster.properties.recordHasNotes} onClick={() => setSelectedMarker(cluster)} />
                        </ClusterMarker>
                    );
                })}
                { userLocation ?
                    <ClusterMarker
                        lat={userLocation.lat}
                        lng={userLocation.lng}
                    >
                        <div className="bg-blue-600 rounded-full w-6 h-6 opacity-80" style={{"transform": "translate(-50%, -50%)", "left": "50%", "top": "50%"}} />
                    </ClusterMarker>
                 : null}
            </GoogleMapReact>
        </div>
    );
}

export default App;
