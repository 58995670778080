import React from "react";
import desired_pin from "./images/pin_desired.png";
import neighbor_pin from "./images/pin_neighbor.png";
import notes_pin from "./images/pin_notes.png";

export default function Marker({type, notes, onClick }) {
    let imgSrc = desired_pin;

    if (notes) {
        imgSrc = notes_pin;
    } else if (type === 2) {
        imgSrc = neighbor_pin;
    }

    return (
        <img onClick={() => onClick()} alt="marker" src={imgSrc} style={{"transform": "translate(-50%, -100%)", "left": "50%", "top": "50%", "width": "48px"}} />
    )
};